<template>
    <div class="box">
        <div class="header">
            <search :sonPlaceholder="placeholder" @sonsearchBut="searchBut"/>
            <!-- <datePicker @selector="selector"/> -->
        </div>
        <div class="content">
            <el-table 
                :data="OrderList" 
                ref="table"
                height="calc(100vh - 240px)"  
                v-loading="loading"
                element-loading-text="拼命加载中">
                <el-table-column
                    fixed
                    align="center"
                    type="index"
                    label="序号"
                    width="58">
                </el-table-column>
                <!-- <el-table-column
                    align="center"
                    prop="finish_time"
                    label="订单完成时间" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.finish_time">{{scope.row.finish_time| dateFormat}}</span>
                        <span>-</span>
                    </template>
                </el-table-column> -->
                <el-table-column
                    align="center"
                    prop="order_id"
                    label="订单ID">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="cost_price"
                    label="成本价">
                     <template slot-scope="scope">
                        ￥{{scope.row.cost_price}}
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    align="center"
                    prop="order_type"
                    label="订单类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order_type=='recharge'">直充</span>
                        <span v-else-if="scope.row.type=='card'">卡券</span>
                        <span v-else-if="scope.row.type=='online'">在线点餐</span>
                    </template>
                </el-table-column> -->
                <el-table-column
                    align="center"
                    prop="amount"
                    label="实付金额">
                    <template slot-scope="scope">
                        ￥{{scope.row.amount}}
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="settlement_amount"
                    label="结算金额">
                    <template slot="header">
                        结算金额
                        <el-tooltip class="item" effect="dark" content="结算金额=用户实付-成本价" placement="bottom">
                            <i class="icon-icon-question iconfont icon"></i>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        ￥{{scope.row.settlement_amount}}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="150" fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="clickDetails(scope.row.order_id,scope.row.order_type)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="footer">
            <paging :sonpagingData="pagingData" @sonHandleSizeChange="handleSizeChange" @sonHandleCurrentChange="handleCurrentChange"/>
        </div>
        <el-drawer :visible.sync="drawer"  :size="1136" :modal-append-to-body="true">
            <onlineOrder :sonorderDetails="orderDetails" v-if="order_type=='1'"></onlineOrder>
            <topUpOrder :sonorderDetails="orderDetails"  v-else-if="order_type=='RechargeProduct'"></topUpOrder>
            <topUpOrder :sonorderDetails="orderDetails"  v-else-if="order_type=='CardVoucher'"></topUpOrder>
        </el-drawer>
    </div>
</template>
<script>
import search from '../../components/search.vue'
import paging from '../../components/paging.vue'
import datePicker from '../../components/datePicker.vue'
import onlineOrder from '../../components/onlineOrder.vue'
import topUpOrder from '../../components/topUpOrder.vue'
import cardOrder from '../../components/cardOrder.vue'
export default {
    components:{
        search,
        paging,
        datePicker,
        onlineOrder,
        topUpOrder,
        cardOrder
    },
    data(){
        return{
            orderDetails:{},// 订单详情数据
            isRecharge:false,
            drawer:false, //订单详情的抽柜显示状态
            search:'',
            start_time:'',
            end_time:'',
            // 搜索提示
            placeholder:'搜索订单ID',
            // 分页数据
            pagingData:{
                page:1,
                max_page:15,
                count:0,
            },
            dialogVisible:true,
            OrderList:[{}],
            loading:false,
            order_type:''
        }
    },
    created(){
        console.log(this.$route.query)
        this.order_type=this.$route.query.type;
        this.getOrderList()
    },
    methods:{
        // 获取列表数据
        async getOrderList(){
            this.loading=true
            const {data:res} = await this.$http.get('/c-admin/order_reconciliation/',{
                params:{
                    search:this.search,
                    type:this.$route.query.type,
                    page:this.pagingData.page,
                    max_page:this.pagingData.max_page,
                    download:false,
                    date:this.$route.query.date
                }
            })
            console.log(res)
            if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
            this.OrderList=res.data.data
            this.pagingData.count=res.data.count
            this.loading=false
        },
        // 接收搜索组件传递过来的值
        searchBut(val){
            console.log(val)
            this.search=val
            this.getOrderList()
        },
        // 接收日期选择的数据
        // selector(data){
        //     console.log(data)
        //     this.start_time=data.start_time
        //     this.end_time=data.end_time
        //     this.getOrderList()
        // },
        // 监听pagesize改变
        handleSizeChange(val) {
            console.log(val)
            this.pagingData.max_page=val
            this.getOrderList()
        },
        // 监听页码值
        handleCurrentChange(val) {
            console.log(val)
            this.pagingData.page=val
            this.getOrderList()
        },
        // 查看订单详情
        async clickDetails(order_id,order_type){
             const{data:res} = await this.$http.get('c-admin/order_info/',{
                params:{
                    order_id:order_id,
                    order_type:order_type
                }
            })
            console.log(res)
            this.orderDetails = res.data;
            if(order_type=='recharge'){
                this.isRecharge=true
            }else{
                this.isRecharge=false
            }
            this.drawer = true;
        },
    }
}
</script>
<style lang="less" scoped>
.box{
    padding: 0 24px;
    .content{
        padding-top: 20px;
    }
    
}
/deep/.el-dialog{
    width: 750px;
    height: 730px;
    overflow-y: scroll;
}
/deep/.el-dialog::-webkit-scrollbar {
    width: 5px;
}
/* 设置滚动条的背景色和圆角 */
/deep/.el-dialog::-webkit-scrollbar-thumb  {
    background-color: rgb(206, 205, 205);
    border-radius: 8px;
}
/* 设置插槽的背景色和圆角 */
/deep/.el-dialog::-webkit-scrollbar-track {
    background-color:rgb(255, 255, 255);
    border-radius:10px;
}
/deep/.el-dialog__body{
    padding: 0 56px 52px 56px;
    .title{
        position: relative;
        width: 162px;
        display: flex;
        justify-content: center;
        h1{
            font-size: 14px;
        }
    }
    .title::after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 162px;
        height: 2px;
        background-image: linear-gradient(to right, #FF6056 , #ffffff);
    }
}
.fonter-color{
    color: #252530;
    font-size: 16px;
    font-weight: 700;
}
.money-color{
    color: #252530;
    font-size: 16px;
    font-weight: 700;
}
</style>